import type { Database } from "../types/supabase";
import { useMutation, useQueryClient } from "@tanstack/vue-query";

export function usePublicProfileId(): Ref<number> {
  const supabaseUser = useUser();
  return computed(() => supabaseUser.value?.app_metadata.public_user_id);
}

export function usePublicProfile(id?: MaybeRef<number | string>) {
  const currentUser = usePublicProfileId();
  const coalescedId = computed(() => parseInt(unref(id)) || currentUser.value);

  return useSupabaseQuery({
    queryKey: () => ["profile", coalescedId.value],
    enabled: computed(() => !!coalescedId.value),
    refetchOnMount: false,
    queryFn: (S) => {
      if (!coalescedId.value) debugger;
      return S.from("users").select("*").eq("id", coalescedId.value).single();
    },
  }).data;
}

export function usePublicProfileMutation() {
  const publicProfileId = usePublicProfileId();
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (patch: Database["public"]["Tables"]["users"]["Update"]) => {
      const id = publicProfileId.value;
      if (!id) throw new Error("invalid user id");
      return client.from("users").update(patch).eq("id", id).returns();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["profile", publicProfileId.value],
      });
    },
  });
}
